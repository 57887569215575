.container-first{
    display: flex;
    justify-content: center;
    color: white;
    margin: 0 5%;
    flex-direction: column;
    padding: 1rem 0;
}

.achievement-detail{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
}

.achievement-image{
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-first img{
    border: 3px solid yellow;
    box-shadow: 0px 0px 15px 0px rgb(169, 32, 32);
}